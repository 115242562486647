import axios from '@/lib/axios/axios';
import NextDelivery from '../../types/api/deliveryMethods/NextDelivery';

export default {
    /**
     * Returns next delivery date and cutoff time.
     */
    getNextDeliveryDeadline: async () => axios.get<NextDelivery>('/delivery-methods/next-delivery'),

    /**
     * A get request to the delivery-methods endpoint will return all avaialble delivery methods based on the
     * the time of the request.
     * @param {number} basketTotal - The total of the current basket.
     * @param {string} basketUUID - The uuid of the basket
     */
    getAllDeliveryMethods: async (basketTotal: number, basketUUID = null) => {
        const queries = [];

        if (basketUUID !== null) queries.push(`basket=${basketUUID}`);
        if (basketTotal !== null) queries.push(`order_cost=${basketTotal}`);

        const queryString = queries.length ? `?${queries.join('&')}` : '';

        return axios.get(`/delivery-methods${queryString}`);
    },

    /**
     * Returns a specific delivery method.
     * @param {number} id - ID of the delivery method to get.
     */
    getDeliveryMethod: async (id: number | string) => axios.get(`/delivery-method/${id}`),
};
