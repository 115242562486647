import React, { Fragment, useMemo } from 'react';
import dayjs from '../../../../lib/dayjs/dayjs';

import { useBasketContext } from '../../../../data/context/basketContext/basketContext';
import formatPrice from '../../../../helpers/formatPrice';
import useDeliveryCutOffCountDown from './hooks/useDeliveryCutOffCountDown/useDeliveryCutOffCountDown';
import useNextDelivery from './hooks/useNextDelivery/useNextDelivery';
import { isTomorrow } from '../../../../helpers/dateFunctions/dateFunctions';

import LoadingIcon from '../../../_ui/_blocks/Icons/LoadingIcon/LoadingIcon';
import Typography from '../../../_ui/_blocks/Typography/Typography';
import Icon from '../../../_ui/_blocks/Icon/Icon';

interface Props {
    hideDeliveryEstimate?: boolean;
    inStock?: boolean;
    className?: string;
}

const AMOUNT_FOR_FREE_DELIVERY = 4000;

/**
 * List of incentives on why a treatment should be bought. Requests delivery information to display to the
 * user from the API.
 */
const Incentives = ({ hideDeliveryEstimate, inStock, className }: Props) => {
    // @ts-expect-error
    const { basket } = useBasketContext();
    const { nextDeliveryDate, cutOffTime, isLoading, error, refetch } = useNextDelivery();

    const deliveryDate = useMemo(() => {
        if (!nextDeliveryDate) {
            return null;
        }

        return isTomorrow(nextDeliveryDate) ? 'Tomorrow' : dayjs(nextDeliveryDate, 'YYYY-MM-DD').format('dddd MMM DD');
    }, [nextDeliveryDate]);

    const timeRemaining = useDeliveryCutOffCountDown({ cutOffTime, onCountDownEnd: refetch });

    const amountRemainingForFreeDelivery = AMOUNT_FOR_FREE_DELIVERY - (basket?.total || 0);
    const qualifiesForFreeDelivery = amountRemainingForFreeDelivery <= 0;

    const freeDeliveryHeading = qualifiesForFreeDelivery ? 'Includes free standard delivery' : 'Free standard delivery on orders over £40';

    const freeDeliveryDescription = qualifiesForFreeDelivery
        ? 'Your order qualifies for free delivery!'
        : `Spend ${formatPrice(amountRemainingForFreeDelivery)} more for free delivery`;

    return (
        <ul className={`space-y-100 ${className || ''}`}>
            <li className="flex gap-050 items-center">
                <Icon icon="truck-light" alt="Delivery truck" size="medium" />
                <div>
                    <Typography as="div" weight='700' size="090" lineHeight="200">
                        {freeDeliveryHeading}
                    </Typography>
                    <Typography as="div" color="quiet" size="080">
                        {freeDeliveryDescription}
                    </Typography>
                </div>
            </li>
            {!error && !hideDeliveryEstimate && inStock ? (
                <li className="flex gap-050 items-center">
                    <Icon icon="calendar-light" alt="Calendar" size="medium" />
                    <div>
                        {isLoading && !nextDeliveryDate ? (
                            <LoadingIcon />
                        ) : (
                            <Fragment>
                                {nextDeliveryDate && (
                                    <Typography as="div" weight='700' size="090" lineHeight="200">
                                        {`Fastest delivery: ${deliveryDate}`}
                                    </Typography>
                                )}
                                {timeRemaining && (
                                    <Typography as="div" color="quiet" size="080">
                                        {`Order within ${timeRemaining}`}
                                    </Typography>
                                )}
                            </Fragment>
                        )}
                    </div>
                </li>
            ) : null}
        </ul>
    );
};

export default Incentives;
