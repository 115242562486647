import React, { createContext, useContext } from 'react';

import useRefillBlockers from './useRefillBlockers';

type UseRefillBlockersReturnType = ReturnType<typeof useRefillBlockers>;

interface Props {
    blockers: UseRefillBlockersReturnType;
    children: React.ReactNode;
}

const RefillBlockerContext = createContext<UseRefillBlockersReturnType>([]);

/**
 * Places any refill blockers in a context so they can be used by the children.
 */
export const RefillBlockerProvider = ({ blockers, children }: Props) => (
    <RefillBlockerContext.Provider value={blockers}>{children}</RefillBlockerContext.Provider>
);

/**
 * Hook to get the refill blockers from the context in the component above.
 */
export const useRefillBlockerContext = () => {
    const refillBasketBlockers = useContext(RefillBlockerContext);
    if (refillBasketBlockers === undefined) throw new Error('useRefillBlockerContext must be used within a RefillBlockerProvider.');
    return refillBasketBlockers;
};
