import { withScope, captureMessage, type Scope } from '@sentry/nextjs'

const isBrowser = () => typeof window !== 'undefined';

/**
 * sendSentryMessage
 * @param {(scope:any) => void} [extraScope] = (scope) => { if (treatmentData) scope.setExtra('Treatment Data', treatmentData); }
 */
const sendSentryMessage = (message: string | Error, extraScope?: (scope: Scope) => void) => {
    if (typeof window !== 'undefined') {
        // eslint-disable-next-line no-undef
        withScope((scope) => {
            if (typeof extraScope === 'function') {
                extraScope(scope);
            }

            // eslint-disable-next-line no-undef
            captureMessage(message as string);
        });
    }
};

if (isBrowser()) {
    window.enableSentryTestMessage = () => {
        window.testSentryMessage = (message: string, extraScope: any) => sendSentryMessage(message, extraScope);
    };
}

export default sendSentryMessage;
