import { useQuery } from 'react-query';

import deliveryMethodsService from '../../../../../../data/services/deliveryMethods.service';

/**
 * Gets the next delivery data from the API.
 */
const useNextDelivery = () => {
    const queryOptions = {
        refetchOnWindowFocus: false,
        retry: false,
    };

    const { data, isFetching, error, refetch } = useQuery(
        'getNextDeliveryDeadline',
        deliveryMethodsService.getNextDeliveryDeadline,
        queryOptions
    );

    const nextDeliveryDate = data?.data.data['next-delivery-date'];
    const cutOffTime = data?.data.data['cutoff-time'];

    return {
        nextDeliveryDate,
        cutOffTime,
        isLoading: isFetching,
        error,
        refetch,
    };
};

export default useNextDelivery;
