import React from 'react';
import clx from 'classnames';
import { Dialog } from '@headlessui/react';

import { getLineHeightClass, lineHeights } from '../../_blocks/Typography/helpers/getLineHeight';
import { getFontSizeClass, fontSizes } from '../../_blocks/Typography/helpers/getFontSize';
import { getTypesetClass, typesets } from '../../_blocks/Typography/helpers/getTypeset';
import { getFontWeightClass, fontWeights } from '../../_blocks/Typography/helpers/getFontWeight';
import { getFontColorClass, fontColors } from '../../_blocks/Typography/helpers/getFontColor';

interface Props extends React.HTMLAttributes<HTMLElement> {
    as?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
    className?: string;
    typeset?: keyof typeof typesets;
    size?: keyof typeof fontSizes;
    lineHeight?: keyof typeof lineHeights;
    weight?: keyof typeof fontWeights;
    color?: keyof typeof fontColors;
    underline?: boolean;
    italic?: boolean;
    children?: React.ReactNode;
}

/**
 * Title for the modal dialog.
 * @see https://headlessui.com/react/dialog#dialog-title
 */
const ModalTitle = ({
    as = 'h3',
    className,
    typeset = 'paragraph',
    size,
    lineHeight,
    weight,
    color,
    underline,
    italic,
    children,
    ...props
}: Props) => {
    const titleClassName = clx(
        className,
        getTypesetClass(typeset),
        getFontWeightClass(weight),
        getFontSizeClass(size),
        getLineHeightClass(lineHeight),
        getFontColorClass(color),
        underline ? 'underline' : '',
        italic ? 'italic' : ''
    );

    return (
        <Dialog.Title as={as} {...props} className={titleClassName}>
            {children}
        </Dialog.Title>
    );
};

export default ModalTitle;
