import React from 'react';

import Card from '../../_ui/_blocks/Card/Card';

interface Props {
    className?: string;
}

const ConsultationReorderConfirmSkeleton = ({ className }: Props) => (
    <Card as="div" className={className}>
        <div className="flex items-center gap-100">
            <div className="skeleton-animation w-[4rem] h-[4rem] flex-shrink-0" />
            <div className="space-y-025 flex-grow">
                <div className="skeleton-animation w-2/3 h-[1.5rem]" />
                <div className="flex items-center gap-050 justify-between">
                    <div className="skeleton-animation w-[4.25rem] h-[1.25rem]" />
                    <div className="skeleton-animation w-[3.75rem] h-[1.25rem]" />
                </div>
            </div>
        </div>
        <div className="flex justify-between mt-200">
            <div className="skeleton-animation w-[3.75rem] h-[1.5rem]" />
            <div className="skeleton-animation w-[4.25rem] h-[1.5rem]" />
        </div>
        <div className="skeleton-animation h-[3.5rem] mt-100" />
    </Card>
);

export default ConsultationReorderConfirmSkeleton;
