class SessionStorageManager {
    // eslint-disable-next-line no-unneeded-ternary
    isSessionStorageAvailable = typeof window === 'undefined' || !window.sessionStorage ? false : true;

    getItem = <T>(key: string): T | null => {
        if (!this.isSessionStorageAvailable) {
            return null;
        }

        return (sessionStorage.getItem(key) as T) || null;
    }

    setItem = <T>(key: string, value: T) => {
        if (!this.isSessionStorageAvailable) {
            return;
        }

        sessionStorage.setItem(key, value as unknown as string);
    }

    removeItem = <T>(key: string): T | null => {
        if (!this.isSessionStorageAvailable) {
            return null;
        }

        const item = this.getItem(key)
        sessionStorage.removeItem(key);
        return item as T;
    }
}

export const sessionStorageManager = new SessionStorageManager();
