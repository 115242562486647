import React, { useState } from 'react';

import { Product } from '../../../types/api/products/Product';
import { PreviousOrder } from '../../../types/api/patient/PreviousOrder';
import { RefillBlockerProvider } from './hooks/useRefillBlockers/RefillBlockerContext';
import useRefill from './hooks/useRefill/useRefill';

import AccountRefillButton from './RefillButtons/AccountRefillButton/AccountRefillButton';
import TreatmentSelector, { SelectorOnChange, SelectorOnSubmit } from '../TreatmentSelector/TreatmentSelector';
import RefillHeader from './RefillHeader/RefillHeader';
import RefillNotifications from './RefillNotifications/RefillNotifications';
import Incentives from '../TreatmentSelector/Incentives/Incentives';
import ConsultationRefillButton from './RefillButtons/ConsultationRefillButton/ConsultationRefillButton';

interface childrenParams {
    variant: any;
}

interface Props {
    treatment: Product;
    previousOrder: PreviousOrder;
    children: (params: childrenParams) => React.ReactNode;
    onSubmit: SelectorOnSubmit;
    onChange?: SelectorOnChange;
    isSubmitting?: boolean;
}

/**
 * Refill component that selects and displays the previously ordered variant and quantity. It also checks
 * that the previous variant and quantity are available and displays any notifications if they aren't. The
 * change button displays the quantity selector to them to change their desired quantity.
 *
 * NOTE: If the button layout needs to change, I would suggest passing through the button component being
 * displayed to the children in a function and rendering it there e.g.
 * const buttonToRender = shouldShowSelector ? TreatmentSelector.button : RefillButton;
 * children(buttonToRender);
 * ((ButtonToRender) => <ButtonToRender>Button text</ButtonToRender)
 */
const TreatmentRefill = ({ onSubmit, onChange, isSubmitting, treatment, previousOrder, children }: Props) => {
    const { variant, quantity, blockers } = useRefill(treatment, previousOrder);

    const [shouldShowSelector, setShouldShowSelector] = useState(() => {
        const numberOfAvailableQuantities = variant?.quantities?.length || 0;
        return numberOfAvailableQuantities > 1 && quantity?.quantity === 1;
    });
    const showSelector = () => setShouldShowSelector(true);

    // This decides if the change quantity button should be hidden if.
    // - It's already been clicked so the quantity options have been displayed.
    // - If there are blockers that are not quantity not available preventing the item from being added to
    //   basket.
    // The button is shown if the blocker is quantity not available as the user can select a new quantity.
    const isButtonHidden =
        shouldShowSelector || blockers.length > 1 || (blockers.length === 1 && blockers[0].type !== 'QUANTITY_NOT_AVAILABLE');

    // Button should be disabled if there are blockers or no quantity. The disabled button will stop the
    // form submission either by click or enter key. This is just in case the user is able to submit the
    // form some how.
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!variant || !quantity || blockers.length) {
            return;
        }

        onSubmit({ variant, quantity });
    };

    return (
        <div className="space-y-200">
            <RefillHeader
                treatment={treatment}
                previousOrder={previousOrder}
                quantity={quantity}
                onChange={showSelector}
                hideButton={isButtonHidden}
            />
            {shouldShowSelector ? (
                <TreatmentSelector
                    onSubmit={onSubmit}
                    onChange={onChange}
                    initialVariant={previousOrder.reference}
                    treatment={treatment}
                    className="space-y-100"
                >
                    <TreatmentSelector.VariantSelector />
                    <TreatmentSelector.QuantitySelector disablePriceComparison />
                    <TreatmentSelector.Notifications conditionName={previousOrder.condition} excludes={['ODONLY']} />
                    <TreatmentSelector.ConsultationButton loading={isSubmitting} className="w-full" />
                    <Incentives />
                </TreatmentSelector>
            ) : (
                <form onSubmit={handleSubmit} className="space-y-100">
                    {variant && quantity ? <TreatmentSelector.Price price={quantity.price} /> : null}
                    <RefillNotifications blockers={blockers} variant={variant} />
                    <RefillBlockerProvider blockers={blockers}>{children({ variant })}</RefillBlockerProvider>
                </form>
            )}
        </div>
    );
};

TreatmentRefill.AccountRefillButton = AccountRefillButton;
TreatmentRefill.ConsultationRefillButton = ConsultationRefillButton;

export default TreatmentRefill;
