import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import sendSentryMessage from '@/lib/sentry/helpers/sendSentryMessage';

import { API_ERROR_MESSAGE } from '../../../constants';
import dispatchGoogleAnalyticsEvent, { gaEvents, gaFormatters } from '../../../modules/analytics/google/dispatchGoogleAnalyticsEvent';
import useStartConsultation from '../hooks/useStartConsultation';

import Typography from '../../_ui/_blocks/Typography/Typography';
import Card from '../../_ui/_blocks/Card/Card';
import Attention from '../../_ui/_blocks/Attention/Attention';
import ConsultationReorderConfirmSkeleton from './ConsultationReorderConfirmSkeleton';
import TreatmentRefill from '../../Treatment/TreatmentRefill/TreatmentRefill';
import Icon from '../../_ui/_blocks/Icon/Icon';
import getPatientType from '@/modules/auth/helpers/getPatientType';


const ConsultationReorderConfirm = ({
    loadingTreatment,
    treatmentError,
    treatmentToReorder,
    treatment,
    onClose,
}) => {
    const startConsultation = useStartConsultation();

    const conditionSlug = get(treatment, 'public.condition.slug', '');

    // Sends ecom analytics data.
    const analyticsHasRun = useRef(false);
    const handleChange = ({ variant, quantity }) => {
        if (analyticsHasRun.current) {
            return;
        }

        const treatmentData = {
            conditionId: treatment.condition_id,
            treatmentId: variant.id,
            quantity: quantity.quantity,
            selectedVariant: variant,
            selectedQuantityInfo: quantity,
            selectedTreatment: treatment,
        };

        dispatchGoogleAnalyticsEvent(gaEvents.RepeatPurchaseStart, gaFormatters.consultation(treatmentData, true, 'REFILL', {
            recChoice: null,
            patientType: getPatientType(),
            hasSignedInAfterAnsweringQuestions: false,
            consultationCategory: 'item',
        }));
        analyticsHasRun.current = true;
    };

    // When the reorder treatment form is submitted.
    const handleSubmit = async ({ variant, quantity }) => {
        const treatmentData = {
            conditionId: treatment.condition_id,
            treatmentId: variant.id,
            quantity: quantity.quantity,
            selectedVariant: variant,
            selectedQuantityInfo: quantity,
            selectedTreatment: treatment,
        };

        dispatchGoogleAnalyticsEvent(gaEvents.RepeatPurchaseComplete, gaFormatters.consultation(treatmentData, true, 'REFILL', {
            recChoice: null,
            patientType: getPatientType(),
            hasSignedInAfterAnsweringQuestions: false,
            consultationCategory: 'item',
        }));

        if(!conditionSlug) {
            sendSentryMessage('No condition slug found in treatment', (scope) => {
                scope.setExtra('conditionSlug', conditionSlug);
                scope.setExtra('Treatment', treatment);
                scope.setExtra('treatmentToReorder', treatmentToReorder);
                scope.setExtra('treatmentData', treatmentData);
                scope.setExtra('treatment.public', get(treatment ,'public'));
                scope.setExtra('treatment.public.condition', get(treatment ,'public.condition'));
            });
        }

        startConsultation(conditionSlug, treatmentData);
    };

    // If there was an error from the APi getting the treatment details.
    const apiError = useMemo(() => {
        if (!treatmentError) {
            return null;
        }

        const errorIs404 = get(treatmentError, 'response.status') === 404;

        return (
            <Attention type={errorIs404 ? 'info' : 'error'} heading={errorIs404 ? 'Product unavailable' : 'Product details error'}>
                <p className="text-content-quiet">{errorIs404 ? 'This product is currently unavailable.' : API_ERROR_MESSAGE}</p>
            </Attention>
        );
    }, [treatmentError]);

    // If the treatment is loading, return the treatment skeleton.
    if (loadingTreatment) {
        return <ConsultationReorderConfirmSkeleton className="w-full container-xsmall" />;
    }

    return (
        <Card as="div" className="w-full space-y-200 container-xsmall mx-auto max-h-[calc(100dvh-(1rem*2))] overflow-auto">
            <header className="flex justify-between">
                <Typography as="h3" typeset="heading" size="100" lineHeight="200">
                    Treatment refill
                </Typography>
                <button type="button" onClick={onClose} title="Close treatment refill">
                    <Icon icon="cross-light" alt="cross" />
                </button>
            </header>
            {apiError || (
                <TreatmentRefill
                    onSubmit={handleSubmit}
                    handleChange={handleChange}
                    treatment={treatment}
                    previousOrder={treatmentToReorder}
                >
                    {({ variant }) => (
                        <TreatmentRefill.AccountRefillButton
                            conditionSlug={conditionSlug}
                            className="w-full text-center"
                            onAlternativesClick={onClose}
                            variant={variant}
                        >
                            Confirm
                        </TreatmentRefill.AccountRefillButton>
                    )}
                </TreatmentRefill>
            )}
        </Card>
    );
};

ConsultationReorderConfirm.defaultProps = {
    treatmentError: null,
    treatment: null,
    treatmentToReorder: null,
};

ConsultationReorderConfirm.propTypes = {
    loadingTreatment: PropTypes.bool.isRequired,
    treatmentError: PropTypes.object,
    treatmentToReorder: PropTypes.shape({
        reference: PropTypes.number.isRequired,
        treatment: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
    }),
    treatment: PropTypes.object,
    onClose: PropTypes.func.isRequired,
};

export default ConsultationReorderConfirm;
