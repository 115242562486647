import axios from '@/lib/axios/axios';

// import { MSW_URL } from '../../config/config';

interface GetAllOptions {
    treatmentIds?: number[];
    withPublicData?: boolean, // true
    filterPriceBreaksByStock?: boolean // true
}

export default {
    /**
     * To get a product, its delivery date potential, blockers and all variants to be able to be selected by
     * the front end system.
     * @param {string} uuid - Unique ID of the product to get.
     */
    getProduct: async (uuid: number) => axios.get(`/products/${uuid}`),

    getProductRefills: async (conditionId: number) => axios.post('/products/refills', { conditionId }),

    getProductRecommendations: async (conditionId: number, treatment?: number) => {
        const body = {
            conditionId,
        } as Record<string, any>;

        if (treatment) {
            body.treatment = treatment;
        }

        return axios.post('/products/recommendation', body);
    },

    getAll: async (options: GetAllOptions) => {
        const config = {
            withPublicData: true,
            filterPriceBreaksByStock: true,
            ...options,
        }

        const queries = [`filterPriceBreaksByStock=${config.filterPriceBreaksByStock}`];

        if (config?.treatmentIds?.length) {
            queries.push(`product_ids=${config.treatmentIds.join(',')}`);
        }

        if (config.withPublicData) {
            queries.push('withPublic');
        }

        const query = queries.length ? encodeURI(`?${queries.join('&')}`) : '';

        return axios.get(`/products${query}`);
    },
};
