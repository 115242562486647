import React from 'react';

import blockingTreatmentNamesToString from '../../TreatmentSelector/Notifications/BlockedByBasketNotification/helpers/blockingTreatmentNamesToString';
import useRefill from '../hooks/useRefill/useRefill';

import OutOfStockNotification from '../../TreatmentSelector/Notifications/OutOfStockNotification/OutOfStockNotification';
import AlreadyInBasketNotification from '../../TreatmentSelector/Notifications/AlreadyInBasketNotification/AlreadyInBasketNotification';
import BlockedByBasketNotification from '../../TreatmentSelector/Notifications/BlockedByBasketNotification/BlockedByBasketNotification';
import GenderNotification from '../../TreatmentSelector/Notifications/GenderNotification/GenderNotification';
import QuantityNotAvailableNotification from './QuantityNotAvailableNotification/QuantityNotAvailableNotification';
import TreatmentUnavailable from '../../TreatmentSelector/Notifications/TreatmentUnavailable/TreatmentUnavailable';

type UseRefillReturnType = ReturnType<typeof useRefill>;

interface Props {
    variant: UseRefillReturnType['variant'];
    blockers: UseRefillReturnType['blockers'];
    className?: string;
}

/**
 * Renders a notification based on the refill blockers given.
 */
const RefillNotifications = ({ blockers, variant, className }: Props) => {
    const blocker = blockers.length ? blockers[0] : null;

    if (blocker) {
        /* eslint-disable indent */
        /* eslint-disable no-case-declarations */
        switch (blocker.type) {
            case 'OUT_OF_STOCK':
                return <OutOfStockNotification variant={variant} isRefill className={className} />;
            case 'ALREADY_IN_BASKET':
                return <AlreadyInBasketNotification className={className} />;
            case 'BLOCKED_BY_BASKET':
                const treatments = blockingTreatmentNamesToString(blocker.data.blockingBasketItemNames);
                return <BlockedByBasketNotification treatments={treatments} className={className} />;
            case 'GENDER_RESTRICTED':
                return <GenderNotification treatmentGender={blocker.data.treatmentGender} patientGender={blocker.data.patientGender} className={className} />;
            case 'QUANTITY_NOT_AVAILABLE':
                return <QuantityNotAvailableNotification className={className} />;
            case 'UNAVAILABLE':
                return <TreatmentUnavailable className={className} />;
            default:
                return null;
        }
        /* eslint-enable no-case-declarations */
        /* eslint-enable indent */
    }

    return null;
};

export default RefillNotifications;
