import React from 'react';

import formatQuantityLabel from '../../helpers/formatQuantityLabel';
import { Product } from '../../../../types/api/products/Product';
import { PreviousOrder } from '../../../../types/api/patient/PreviousOrder';
import useRefill from '../hooks/useRefill/useRefill';

import Typography from '../../../_ui/_blocks/Typography/Typography';
import Attention from '../../../_ui/_blocks/Attention/Attention';
import ImageWithFallback from '../../../NextJs/ImageWithFallback/ImageWithFallback';

import styles from './RefillHeader.module.css';

type UseRefillReturnType = ReturnType<typeof useRefill>;

interface Props {
    treatment: Product;
    previousOrder: PreviousOrder;
    quantity: UseRefillReturnType['quantity'];
    onChange: () => void;
    hideButton: boolean;
}

const RefillHeader = ({ treatment, previousOrder, quantity, onChange, hideButton }: Props) => {
    // If the quantity label is different to the previous order label, we need the customer to check the
    // changes before proceeding.
    const areThereRefillDiscrepancies = quantity ? formatQuantityLabel(quantity) !== formatQuantityLabel(previousOrder) : false;

    return (
        <header className="space-y-100">
            <div className="flex items-center gap-100">
                <ImageWithFallback
                    src={!Array.isArray(treatment.public) ? treatment.public.images.featuredUrl : null}
                    fallback="/images/pill.svg"
                    alt={treatment.name}
                    width={64}
                    height={64}
                    className={styles.image}
                />
                <div className="w-full">
                    <Typography as="h4" typeset="heading" size="100">
                        {treatment.name}
                    </Typography>
                    <div className="flex justify-between items-center gap-050">
                        <Typography as="div" typeset="subheading" size="080" color="quiet">
                            {formatQuantityLabel(quantity || previousOrder)}
                        </Typography>
                        {!hideButton ? (
                            <button type="button" title="Change the quantity" className="type-subheading type-080" onClick={onChange}>
                                <span className="type-link">Change</span>
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
            {areThereRefillDiscrepancies ? (
                <Attention heading="Product has changed since your last refill">
                    <p>Please double check the pack size and price before confirming your refill.</p>
                </Attention>
            ) : null}
        </header>
    );
};

export default RefillHeader;
