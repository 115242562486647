import React from 'react';
import clx from 'classnames';

import styles from './LoadingIcon.module.css';
import Icon from '../../Icon/Icon';

interface Props extends Omit<React.ComponentProps<typeof Icon>, 'children' | 'icon' | 'alt'> {
  animate?: boolean;
}

const LoadingIcon = ({ className, ...rest }: Props) => {
  const classNames = clx(styles.animate, className);

  return (
    <Icon icon="loading" size="medium" alt="Loading" aria-hidden="true" className={classNames} {...rest} />
  );
};

export default LoadingIcon;