import React from 'react';

import { useRefillBlockerContext } from '../../hooks/useRefillBlockers/RefillBlockerContext';

import OpenBasketButton from '../../../TreatmentSelector/SelectorButtons/Buttons/OpenBasketButton/OpenBasketButton';
import ButtonBusy from '../../../../_ui/_blocks/Buttons/ButtonBusy/ButtonBusy';

type ButtonBusyProps = React.ComponentProps<typeof ButtonBusy>;

type Props = Pick<ButtonBusyProps, 'className' | 'size' | 'loading' | 'children'>;

/**
 * Decides what submission button to show during the refill process for the treatment selector.
 */
const ConsultationRefillButton = ({ className, loading, children, size }: Props) => {
    const refillBlockers = useRefillBlockerContext();

    const blockerTypes = refillBlockers.map(({ type }) => type);

    const props = {
        className,
        size,
        loading,
    };

    if (blockerTypes.includes('ALREADY_IN_BASKET') || blockerTypes.includes('BLOCKED_BY_BASKET')) {
        return <OpenBasketButton {...props} />;
    }

    if (blockerTypes.length) {
        return null;
    }

    return (
        <ButtonBusy {...props} type="submit" title="Refill treatment">
            {children}
        </ButtonBusy>
    );
};

export default ConsultationRefillButton;
