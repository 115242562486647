import React from 'react';
import clx from 'classnames';
import { Dialog } from '@headlessui/react';

import { getLineHeightClass, lineHeights } from '../../_blocks/Typography/helpers/getLineHeight';
import { getFontSizeClass, fontSizes } from '../../_blocks/Typography/helpers/getFontSize';
import { getTypesetClass, typesets } from '../../_blocks/Typography/helpers/getTypeset';
import { getFontWeightClass, fontWeights } from '../../_blocks/Typography/helpers/getFontWeight';
import { getFontColorClass, fontColors } from '../../_blocks/Typography/helpers/getFontColor';

interface Props extends React.HTMLAttributes<HTMLElement> {
    as?: 'span' | 'p' | 'div';
    className?: string;
    typeset?: keyof typeof typesets;
    size?: keyof typeof fontSizes;
    lineHeight?: keyof typeof lineHeights;
    weight?: keyof typeof fontWeights;
    color?: keyof typeof fontColors;
    underline?: boolean;
    italic?: boolean;
    children?: React.ReactNode;
}

/**
 * Description for the modal dialog.
 * @see https://headlessui.com/react/dialog#dialog-description
 */
const ModalDescription = ({
    as = 'span',
    className,
    typeset = 'paragraph',
    size,
    lineHeight,
    weight,
    color,
    underline,
    italic,
    children,
    ...props
}: Props) => {
    const descriptionClassName = clx(
        className,
        getTypesetClass(typeset),
        getFontWeightClass(weight),
        getFontSizeClass(size),
        getLineHeightClass(lineHeight),
        getFontColorClass(color),
        underline ? 'underline' : '',
        italic ? 'italic' : ''
    );

    return (
        <Dialog.Description as={as} {...props} className={descriptionClassName}>
            {children}
        </Dialog.Description>
    );
};

export default ModalDescription;
