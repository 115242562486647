import React from 'react';

import Attention from '../../../../_ui/_blocks/Attention/Attention';

type Props = Pick<React.ComponentProps<typeof Attention>, 'className'>;

const QuantityNotAvailableNotification = ({ className }: Props) => (
    <Attention heading="Quantity not available" className={className}>
        The quantity of this treatment could not be found. Please select a different quantity.
    </Attention>
);

export default QuantityNotAvailableNotification;
