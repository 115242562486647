import { ProductsQuantity } from '../../../../../../../../types/api/products/ProductsQuantity';

/**
 * Takes the ProductsQuantity object from a timed treatment and a new quantity. Then returns a clone of the
 * ProductsQuantity with an updated quantity and price value to match the new quantity.
 */
const modifyTimedQuantityWithNewQuantity = (quantity: ProductsQuantity, newQuantity: number) => {
    const { unit_cost: unitCost } = quantity;

    return {
        ...quantity,
        quantity: newQuantity,
        price: unitCost * newQuantity,
    };
};

export default modifyTimedQuantityWithNewQuantity;
