import { useMemo } from 'react';

import useRefillBlockers from '../useRefillBlockers/useRefillBlockers';
import modifyTimedQuantityWithNewQuantity from '../../../TreatmentSelector/TreatmentSelectorContext/hooks/useVariantQuantityReducer/helpers/modifyTimedQuantityWithNewQuantity/modifyTimedQuantityWithNewQuantity';
import { Product } from '../../../../../types/api/products/Product';
import { PreviousOrder } from '../../../../../types/api/patient/PreviousOrder';

const useRefill = (treatment: Product, previousOrder: PreviousOrder) => {
    const variant = useMemo(() => {
        const foundVariant = treatment.variants.filter(({ available }) => available).find(({ id }) => id === previousOrder.reference);
        return foundVariant || null;
    }, [treatment, previousOrder]);

    const quantity = useMemo(() => {
        if (!variant || !variant.quantities.length) {
            return null;
        }

        if (variant.quantity_type === 'TIMED') {
            return modifyTimedQuantityWithNewQuantity(variant.quantities[0], previousOrder.quantity);
        }

        return variant.quantities.find((q) => q.quantity === previousOrder.quantity) || null;
    }, [variant, previousOrder]);

    const blockers = useRefillBlockers(variant, quantity);

    return {
        variant,
        quantity,
        blockers,
    };
};

export default useRefill;
