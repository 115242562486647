/**
 * Formats a dayjs duration to a string of two or one time values.
 * - Days and hours
 * - Hours and minutes
 * - Minutes and seconds
 * - Seconds
 */
const formatTimeRemaining = (timeRemaining: plugin.Duration) => {
    const days = timeRemaining.days();
    const hours = timeRemaining.hours();
    const minutes = timeRemaining.minutes();
    const seconds = timeRemaining.seconds();

    const labels = {
        days: days === 1 ? 'day' : 'days',
        hours: hours === 1 ? 'hour' : 'hours',
        minutes: minutes === 1 ? 'minute' : 'minutes',
        seconds: seconds === 1 ? 'second' : 'seconds',
    };

    /* eslint-disable indent */
    switch (true) {
        case days > 0:
            return `${days} ${labels.days} ${hours} ${labels.hours}`;
        case hours > 0:
            return `${hours} ${labels.hours} ${minutes} ${labels.minutes}`;
        case minutes > 0:
            return `${minutes} ${labels.minutes} ${seconds} ${labels.seconds}`;
        default:
            return `${seconds} ${labels.seconds}`;
    }
    /* eslint-enable indent */
};

export default formatTimeRemaining;
